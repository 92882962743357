
import Card from "@/components/base/card/Card"
import Button from "@/components/base/Button"

import ProgressLinear from "@/components/base/ProgressLinear"
import livestreamService from "@/services/livestream.service"
import {
    mdiCheckboxBlankCircleOutline,
    mdiCheckboxMarkedCircle,
    mdiEye,
} from "@mdi/js"
import { mapGetters } from "vuex"

export default {
    name: "PollCard",
    components: { ProgressLinear, Button, Card },
    /** edit-btn-click: click on the edit button */
    emits: ["edit-btn-click", "edit-proposal-btn-click"],
    props: {
        /** poll object:
         *  id: Number
         *  proposal: Object
         *  headline: String
         *  voting_ended_at: String
         *  options: Object:
         *      id: number
         *      text: string
         *      icon: string
         *      votes: number
         * */
        poll: {
            type: Object,
            required: true,
        },
        /** shows if the poll is in creator mode  */
        showCreatorMode: {
            type: Boolean,
            default: false,
        },
        /** icons for polls (object containig icons)  */
        pollIcons: {
            type: Object,
            default: () => ({}),
        },
    },
    data: function () {
        return {
            mdiCheckboxBlankCircleOutline,
            mdiCheckboxMarkedCircle,
            mdiEye,
            isActiveBtnDisabled: false,
            vote: null,
            editBtnImage: require("@/assets/icons/v2.1/icon_pen.svg"),
        }
    },
    computed: {
        ...mapGetters("livestream", ["verifyPassword"]),
        isVoteBtnDisabled() {
            return !this.showCreatorMode && !this.isActive
        },
        ativeLabel() {
            return this.isActive ? "aktiv" : "inaktiv"
        },
        sumVotes() {
            return this.poll.options.reduce((sum, { votes }) => {
                return sum + votes
            }, 0)
        },
        clickEvent() {
            return this.showCreatorMode ? null : "click"
        },
        showResults() {
            return this.showCreatorMode || !!this.endedAt
        },
        endedAt() {
            return this.poll.voting_ended_at ? this.poll.voting_ended_at : false
        },
        isActive() {
            return this.poll.is_active === 1
        },
    },
    created() {
        this.restoreVote()
    },
    methods: {
        onEditVoteBtnClick() {
            this.$emit("edit-btn-click")
        },
        onActiveBtnClick() {
            this.isActiveBtnDisabled = true
            livestreamService.putLivestreamPoll(this.poll.id, {
                is_active: this.isActive ? 0 : 1,
            })
            setTimeout(() => {
                this.isActiveBtnDisabled = false
            }, 1000)
        },
        async onVoteBtnClick(option) {
            try {
                await livestreamService.postLivestreamPollOptionVotes({
                    livestream_poll_option_id: option.id,
                    user_session_id: localStorage.getItem("user_session_id"),
                    password: this.verifyPassword,
                })
                this.vote = option.id
                this.saveVote()
            } catch (e) {
                if (e.response.status == 403) {
                    /* PW Changed */
                    this.$emit("vote-btn-click", {
                        handler: this.onVoteBtnClick,
                        option,
                    })
                } else {
                    console.error(e)
                }
            }
        },
        onProposalEdit() {
            this.$emit("edit-proposal-btn-click")
        },
        onProposalShow() {
            this.$emit("show-proposal-btn-click")
        },
        isOptionSelected(option) {
            return option.id === this.vote
        },
        getPercentage(option) {
            if (this.sumVotes) return (option.votes / this.sumVotes) * 100
            else return 0
        },
        saveVote() {
            const userSessionId = localStorage.getItem("user_session_id")
            if (!userSessionId || !this.poll.id || !this.vote) return
            localStorage.setItem(
                `vote-${userSessionId}-${this.poll.id}`,
                JSON.stringify(this.vote)
            )
        },
        restoreVote() {
            const userSessionId = localStorage.getItem("user_session_id")
            if (!userSessionId) return
            const vote = localStorage.getItem(
                `vote-${userSessionId}-${this.poll.id}`
            )
            if (vote) this.vote = JSON.parse(vote)
        },
    },
}
