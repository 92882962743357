
export default {
    name: "ValidationResponse",
    props: {
        dismissible: {
            type: Boolean,
            default: true,
        },
        /** Response object */
        data: {
            type: [Object, String, Error],
            default: null,
        },
    },
    data: () => ({
        type: "error",
        show: false,
        message: "",
    }),
    watch: {
        data: function (newVal) {
            if (!newVal) {
                this.show = false
            } else {
                if (newVal instanceof Error) {
                    this.type = "error"
                    this.message = newVal.message
                    this.show = true
                } else if (typeof newVal.errors !== "undefined") {
                    this.type = "error"
                    let errors = Object.values(newVal.errors)
                    this.message = errors.flat().join(" ")
                    this.show = true
                } else if (typeof newVal.message !== "undefined") {
                    this.message = newVal.message
                    this.type = "success"
                    this.show = true
                } else {
                    this.message = newVal
                }
            }
        },
    },
}
