
import { mdiClose, mdiPlusBoxOutline } from "@mdi/js"
import Card from "@/components/base/card/Card"
import rules from "@/mixins/rules"
import Button from "@/components/base/Button"
import livestreamService from "@/services/livestream.service"
import ValidationResponse from "@/components/base/api/ValidationResponse"

export default {
    name: "PollCreatorCard",
    components: { ValidationResponse, Button, Card },
    mixins: [rules],
    /** close: when editor closed */
    emits: ["close"],
    props: {
        /** id of the current livestream */
        livestreamId: {
            type: Number,
            required: true,
        },
        /** maximum number of options */
        maxOptions: {
            type: Number,
            default: 5,
        },
        /** if the component is opened to edit an existing poll  */
        editMode: {
            type: Boolean,
            default: false,
        },
        /** poll to edit:
         *    id: Number,
         *    headline: String,
         *    is_active: Boolean,
         *    voting_ended_at: DateTime,
         *    options: Array of options:
         *      id: Number,
         *      text: String,
         *      votes: Number,
         *      icon: String,
         *  */
        poll: {
            type: Object,
            default: () => ({}),
        },
        /** icons for polls (object containig icons)  */
        pollIcons: {
            type: Object,
            default: () => ({}),
        },
    },
    data: function () {
        return {
            isOpen: false,
            isPollValid: false,
            isPollUpdating: false,
            validationResponseData: null,
            mdiClose,
            mdiPlusBoxOutline,
            immediatlyActive: true,
            resultsVisibility: false,
            headline: "",
            options: [
                {
                    icon: "mdiThumbUp",
                    text: "ich stimme zu",
                },
                {
                    icon: "mdiThumbDown",
                    text: "ich stimme nicht zu",
                },
                {
                    icon: "placeholder",
                    text: null,
                },
            ],
        }
    },
    computed: {
        pollIconsInternal() {
            return {
                placeholder: mdiPlusBoxOutline,
                ...this.pollIcons,
            }
        },
    },
    watch: {
        options(value) {
            if (!Array.isArray(value)) return
            // add new option if the last one doesn't have default values
            const lastOption = value[value.length - 1]
            const isLastOptionChanged =
                lastOption.text || lastOption.icon !== "placeholder"
            if (isLastOptionChanged && value.length < this.maxOptions)
                this.addOption()
        },
        isPollUpdating(value) {
            if (value) this.validationResponseData = null
        },
    },
    created() {
        if (this.editMode && this.poll) {
            this.headline = this.poll.headline
            this.options = this.poll.options
        }
    },
    methods: {
        closeCreator() {
            this.$emit("close")
        },
        getOptionRules(optionIndex) {
            return this.isLastOption(optionIndex) ? [] : [this.ruleRequired()]
        },
        getOptionPlaceholder(optionIndex) {
            return this.isLastOption(optionIndex)
                ? "Antwortmöglichket hinzufingen"
                : null
        },
        getIconClasses(icon) {
            const c = {
                "grey-icon": icon === "placeholder" || this.isPollUpdating,
            }
            return c
        },
        isLastOption(optionIndex) {
            return this.options.length - 1 === optionIndex
        },
        addOption() {
            this.options.push({
                icon: "placeholder",
                text: null,
            })
        },
        async deleteOption(optionIndex) {
            if (this.editMode && this.options[optionIndex].id) {
                this.isPollUpdating = true
                try {
                    await livestreamService.deleteLivestreamPollOption(
                        this.options[optionIndex].id
                    )
                } catch (e) {
                    this.validationResponseData = e.response.data
                }
                this.isPollUpdating = false
            }
            // this way the reactivity of Vue still works
            this.options.splice(optionIndex, 1)
        },
        async changeOption(text, icon, optionIndex) {
            // this way the reactivity of Vue still works
            this.options.splice(optionIndex, 1, {
                ...this.options[optionIndex],
                icon: icon || this.options[optionIndex].icon,
                text: text || this.options[optionIndex].text,
            })
        },
        async savePoll() {
            this.isPollUpdating = true
            const pollData = {
                headline: this.headline,
                livestream_id: this.livestreamId,
                is_active: this.immediatlyActive ? 1 : 0,
            }
            try {
                const poll = await livestreamService.postLivestreamPoll(
                    pollData
                )
                await this.updateOptions(poll.id)
                this.closeCreator()
            } catch (e) {
                this.validationResponseData = e.response.data
            }
            this.isPollUpdating = false
        },
        async updatePoll() {
            this.isPollUpdating = true
            const pollData = {
                headline: this.headline,
            }
            try {
                await livestreamService.putLivestreamPoll(
                    "" + this.poll.id,
                    pollData
                )
                await this.updateOptions(this.poll.id)
                this.closeCreator()
            } catch (e) {
                this.validationResponseData = e.response.data
            }
            this.isPollUpdating = false
        },
        async deletePoll() {
            this.isPollUpdating = true
            try {
                await livestreamService.deleteLivestreamPoll(this.poll.id)
                this.closeCreator()
            } catch (e) {
                this.validationResponseData = e.response.data
            }
            this.isPollUpdating = false
        },
        async updateOptions(pollId) {
            const options = this.options
                .map(({ text, icon, id }) => {
                    if (!text) return null
                    else
                        return {
                            livestream_poll_id: pollId,
                            icon,
                            text,
                            id,
                        }
                })
                .filter((option) => !!option)
            for (let i in options) {
                const option = options[i]
                if (this.editMode && option.id) {
                    await livestreamService.putLivestreamPollOption(
                        option.id,
                        option
                    )
                } else {
                    await livestreamService.postLivestreamPollOption(option)
                }
            }
        },
    },
}
