
import TextArea from "@/components/base/input/TextArea"
import Button from "@/components/base/Button"
import ValidationResponse from "@/components/base/api/ValidationResponse"

import livestreamService from "@/services/livestream.service"
import sanitizeText from "@/utils/sanitize-text"

export default {
    name: "Proposal",
    components: { TextArea, Button, ValidationResponse },
    emits: ["close"],
    props: {
        /* Makes proposal editable */
        editable: {
            type: Boolean,
            default: false,
        },
        /* Proposal-Object to be shown */
        proposal: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        error: null,
        internalProposal: {},
    }),
    watch: {
        proposal(newVal) {
            this.internalProposal = newVal || {}
        },
    },
    mounted() {
        this.internalProposal = {
            ...this.proposal,
        } /* Destructure vuex object */
    },
    methods: {
        onSave() {
            livestreamService
                .putLivestreamPollProposal(
                    this.internalProposal.id,
                    this.internalProposal
                )
                .then((resp) => this.$emit("close", resp))
                .catch((err) => (this.error = err))
        },
        onClose() {
            this.$emit("close")
        },
        sanitize(text) {
            return sanitizeText(text, true)
        },
    },
}
