
import Button from "@/components/base/Button"
export default {
    name: "RequestVerify",
    components: { Button },
    data: () => ({
        verifyInput: "",
        verifyError: "",
    }),
    methods: {
        async onVerifyValidate() {
            if (!this.verifyInput) return
            const success = await this.$store.dispatch(
                "livestream/verifyLogin",
                this.verifyInput
            )
            if (success) {
                this.$emit("accept")
            } else {
                this.verifyError = "Passwort inkorrekt"
                this.verifyInput = ""
                setTimeout(() => (this.verifyError = ""), 5000)
            }
        },
        onVerifyClose() {
            this.verifyInput = ""
            this.$emit("close")
        },
    },
}
