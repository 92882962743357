
import PollCard from "@/components/livestream/PollCard"
import PollCreatorCard from "@/components/livestream/PollCreatorCard"
import Proposal from "@/components/livestream/Proposal"
import Modal from "@/components/base/modal/Modal"
import SecureSettings from "@/components/livestream/SecureSettings"
import LoginToSendVote from "@/components/user/RequestLogin"
import RequestVerify from "@/components/livestream/RequestVerify"
import Button from "@/components/base/Button"

import { mdiPlus, mdiThumbDown, mdiThumbUp } from "@mdi/js"
import { mapGetters } from "vuex"

export default {
    name: "LivestreamPolls",
    components: {
        Button,
        PollCard,
        PollCreatorCard,
        Proposal,
        Modal,
        SecureSettings,
        LoginToSendVote,
        RequestVerify,
    },
    data: function () {
        return {
            mdiPlus,
            isEditorOpen: false,
            isEditing: false,
            pollToEdit: {},
            pollIcons: {
                mdiThumbUp,
                mdiThumbDown,
            },
            selectedProposalId: null,
            editingProposal: false,
            showVerifyModal: false,
            showLoginModal: false,
            verifyInput: "",
            verifyError: "",
            pendingVote: null,
            showLessPolls: true,
        }
    },
    computed: {
        ...mapGetters("livestream", {
            polls: "polls",
            hasPolls: "hasPolls",
            id: "id",
            isSecure: "isSecure",
            verifyPassword: "verifyPassword",
            shouldLoginToVote: "shouldLoginToVote",
        }),
        canDisplayLessPolls() {
            return (
                this.$breakpoint.xsOnly &&
                !this.isLivestreamHost &&
                this.polls.length > 1
            )
        },
        displayedPolls() {
            if (this.canDisplayLessPolls && this.showLessPolls)
                return [this.polls[0]]
            return this.polls
        },
        isLivestreamHost() {
            return this.$can.admin() || this.$can.adminPolls()
        },
        selectedProposal() {
            const poll = this.polls.find(
                (poll) => poll.proposal?.id == this.selectedProposalId
            )
            return poll?.proposal
        },
        needsVerify() {
            return (
                this.isSecure && !this.verifyPassword && !this.isLivestreamHost
            )
        },
        needsLogin() {
            return this.shouldLoginToVote && !this.$auth.loggedIn
        },
    },
    methods: {
        openEditor(pollIndex) {
            if (this.isEditorOpen) return
            if (pollIndex || pollIndex === 0) {
                this.pollToEdit = JSON.parse(
                    JSON.stringify(this.polls[pollIndex])
                )
                this.isEditing = true
            }
            this.isEditorOpen = true
        },
        openProposalEditor(proposal) {
            this.selectedProposalId = proposal.id
            this.editingProposal = true
        },
        openProposal(proposal) {
            this.selectedProposalId = proposal.id
        },
        closeEditor() {
            this.isEditing = false
            this.isEditorOpen = false
        },
        onProposalClose() {
            this.selectedProposalId = null
            this.editingProposal = false
        },
        onRequestAccess(vote = null) {
            this.pendingVote = vote
            if (!this.needsLogin) {
                this.showVerifyModal = true
                this.$store.dispatch("livestream/removePasswordLocalStorage")
            } else {
                this.showLoginModal = true
            }
        },
        async onValidated() {
            this.showVerifyModal = false
            if (this.pendingVote) {
                /* Execute Vote */
                this.pendingVote.handler(this.pendingVote.option)
            }
            this.pendingVote = null
        },
        onAccessClose() {
            this.pendingVote = null
            this.showVerifyModal = false
            this.showLoginModal = false
        },
        toggleShowLessPolls() {
            this.showLessPolls = !this.showLessPolls
        },
    },
}
