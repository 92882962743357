
import Button from "@/components/base/Button"
import { mapGetters } from "vuex"

export default {
    name: "SecureSettings",
    components: { Button },
    data: () => ({
        isApiLoading: false,
        passwordInput: "",
    }),
    computed: {
        ...mapGetters("livestream", ["isSecure", "shouldLoginToVote"]),
        isSecureMode: {
            get() {
                return this.isSecure
            },
            async set(newVal) {
                this.isApiLoading = true
                await this.$store
                    .dispatch("livestream/sendLivestreamUpdateRequest", {
                        is_secure: newVal,
                    })
                    .catch((err) => console.warn(err))
                this.isApiLoading = false
            },
        },
        isLoginMode: {
            get() {
                return this.shouldLoginToVote
            },
            async set(newVal) {
                this.isApiLoading = true
                await this.$store
                    .dispatch("livestream/sendLivestreamUpdateRequest", {
                        account_only_vote: newVal,
                    })
                    .catch((err) => console.warn(err))
                this.isApiLoading = false
            },
        },
    },
    methods: {
        async onVerifyPasswordChange() {
            this.isApiLoading = true
            await this.$store
                .dispatch("livestream/sendLivestreamUpdateRequest", {
                    password: this.passwordInput,
                })
                .catch((err) => console.warn(err))
            this.isApiLoading = false
        },
    },
}
