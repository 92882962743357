
export default {
    name: "TextArea",
    props: {
        inset: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        focus() {
            this.$refs.textarea?.focus()
        },
    },
}
